import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ShareButtons2 from '../components/ShareButtons2'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  time,
  date,
  previous,
  next,
}) => {
  const PostContent = contentComponent || Content
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const twitterHandle = 'neederland'
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p>
              {date}&nbsp;᛫&nbsp;{time}
            </p>
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div>
              <ShareButtons2
                title={title}
                url={url}
                twitterHandle={twitterHandle}
                tags={tags}
              /><br/>
            </div>
            <p>{description}</p>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', flexFlow: 'row wrap'}} className="blogNextPrev">
    
            {previous && (
             <>
              <Link to={previous.fields.slug} className="button is-link">
                «&nbsp;{' '}
                
                  {previous.frontmatter.title}
                </Link>
              </>
            )}
    
 
          {next && (
          <>
          <Link className="button is-link" to={next.fields.slug}>

                
                
                
               {next.frontmatter.title}&nbsp;{' '}»     
        </Link>
          </>
          )}
 
        </div>
        </div>
        </div>
        </div>
      
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  time: PropTypes.string,
  date: PropTypes.string,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  const { previous, next } = pageContext

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        time={post.fields.readingTime.text}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        previous={previous}
        next={next}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
