import React, { useState } from 'react'
import link from '../img/share/link.svg'
import check from '../img/share/check.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'

const ShareButtons2 = ({ title, url, twitterHandle, tags }) => {
  const [copied, setCopied] = useState(false)
  const handleOnCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <div className="social">
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} round={true}
        className="shareButtons2"/>
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={40} round={true} 
        className="shareButtons2"/>
      </TwitterShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={40} round={true} 
        className="shareButtons2"/>
      </LinkedinShareButton>

      <RedditShareButton url={url} title={title}>
        <RedditIcon size={40} round={true} 
        className="shareButtons2"/>
      </RedditShareButton>

      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={40} round={true} 
        className="shareButtons2"/>
      </WhatsappShareButton>
      <CopyToClipboard onCopy={handleOnCopy} text={url}>
        <div className={'socialShare-icon'}>
          <img
            src={copied ? check : link}
            alt="link"
            style={{ width: '1em', height: '1em' }}
          />
        </div>
      </CopyToClipboard>
    </div>
  )
}
export default ShareButtons2
